"use client";
import {
  initJwtRefreshTimer,
  startJwtRefreshTimer,
} from "#app/(unauthorized)/authentication/jwtTokenRefreshTimer";
import { useIcIFrameSessionManager } from "#app/_ui/components/ic/IcIFrameSessionManager";
import { useQueryClient } from "@tanstack/react-query";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { getCurrentJwt, Segment } from "./jwt";
import { useJwt } from "./useJwt";

const requiredActivityPath = [
  { key: "MustMFA", path: "/sign-in/mfa" },
  { key: "MustAcceptTermsAndConditions", path: "/sign-in/terms-and-conditions" },
  { key: "MustChangePassword", path: "/changepassword" },
];

export const JwtProviderContext = createContext({
  setRequiredDataLoaded: () => {},
  clearRequiredData: () => {},
});
export function JwtProvider({ children }: PropsWithChildren) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const jwt = useJwt();
  const [requiredDataLoaded, setRequiredDataLoaded] = useState<boolean>(false);
  const contextData = useMemo(() => {
    return {
      setRequiredDataLoaded: () => setRequiredDataLoaded(true),
      clearRequiredData: () => setRequiredDataLoaded(false),
    };
  }, []);
  const getRequiredActivityRedirectPath = useCallback(
    (currentPath: string, requiredActivities: string[]) => {
      for (let i = 0; i < requiredActivityPath.length; i++) {
        const raPath = requiredActivityPath[i]!;
        if (currentPath == raPath.path) {
          return null;
        } // We're currently on a required activity page, we are where we need to be
        if (requiredActivities.includes(raPath.key)) {
          return raPath.path;
        }
      }
      return null;
    },
    [],
  );

  useLayoutEffect(() => {
    if (
      jwt.segment != Segment.NotLoggedIn &&
      !["/sign-out", "/sign-in"].includes(pathname)
    ) {
      if (jwt.requiredActivities.length > 0) {
        let path = getRequiredActivityRedirectPath(
          pathname,
          jwt.requiredActivities,
        );
        if (path) {
          if (pathname != path) {
            path += `?redirectUrl=${pathname}&token=${encodeURIComponent(searchParams.get("token") ?? "")}`;
          }

          router.push(path);
        }
      }
    }
  }, [
    pathname,
    router,
    getRequiredActivityRedirectPath,
    requiredDataLoaded,
    jwt,
    searchParams,
  ]);

  useEffect(() => {
    initJwtRefreshTimer(()=> router.push("/sign-out"));
    startJwtRefreshTimer();
  }, [router]);

  return (
    <JwtProviderContext.Provider value={contextData}>
      {children}
    </JwtProviderContext.Provider>
  );
}

export function useJwtLogout() {
  const queryClient = useQueryClient();
  const context = useContext(JwtProviderContext);
  const icIFrameSessionManager = useIcIFrameSessionManager();
  return () => {
    const jwt = getCurrentJwt();
    icIFrameSessionManager.signOut();
    jwt.logout();
    context.clearRequiredData();
    queryClient.clear();
  }
}