"use client";
import { getCurrentJwt, Segment } from "#app/(unauthorized)/authentication/jwt";
import { useSamlQueryOptions } from "#app/_api/authentication-service-saml";
import config from "#app/env";
import { TimeSpan } from "#app/lib/TimeSpan";
import { useQuery } from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { bindIcFunctions } from "./api";

type IcIframeWithResizer = HTMLIFrameElement & { iFrameResizer: { sendMessage :(obj: any) => void }};

export type IcIframeSessionManager = {
    signOut: () => void
}

export const IcIframeSessionContext = createContext<IcIframeSessionManager>({ signOut: () => {} })
export function IcIframeSessionProvider({ children }: PropsWithChildren) {
    const { segment, expiration } = getCurrentJwt();
    const [signedOut, setSignedOut] = useState(false);
    const { data } = useQuery({
        ...useSamlQueryOptions(),
        enabled: !signedOut && segment !== Segment.NotLoggedIn
    });
    const iframeRef = useRef<IcIframeWithResizer>(null);
    const formRef = useRef<HTMLFormElement>(null);

    function signOut() {
        setSignedOut(true);
        if(iframeRef.current) {
            iframeRef.current.iFrameResizer?.sendMessage({ eventType: "LOGOUT" });
        }
    }

    useEffect(() => {
        let interval = null;
        if (segment !== Segment.NotLoggedIn && data) {
            const iframe = iframeRef.current;
            formRef.current?.submit()
            interval = setInterval(() => {
                if(iframe) {
                    iframe.iFrameResizer?.sendMessage({ eventType: "GlobalExtendSession" });
                }
            }, TimeSpan.fromMinutes(2).totalMilliseconds);
        }

        return () => {
            if (interval)
                clearInterval(interval);
        }
    }, [data, segment, expiration])
    
    if (!data)
        return children;

    return (
        <IcIframeSessionContext.Provider value={{ signOut }}>
            <form ref={formRef} className="hidden" action={config!.icIframeUrl!} method="POST" target="iciframesessionmanager">
                <input type="hidden" name="RelayState" value={config!.icUrl!}/>
                <input type="hidden" name="SAMLResponse" value={data}/>
            </form>
            <iframe onLoad={()=> bindIcFunctions(config!.icUrl!)} ref={iframeRef} width="0" height="0" className="hidden" name="iciframesessionmanager" />
            {children}
        </IcIframeSessionContext.Provider>
    );
}

export function useIcIFrameSessionManager() {
    return useContext(IcIframeSessionContext);
}